import { useState } from "react";
import { truncateString } from "../../utils";
import { UncontrolledTooltip } from "reactstrap";
import { FaInfoCircle } from "react-icons/fa";

export const TopThreeBoard = ({ type, gameplayData, winsData }) => {
  const [copied, setCopied] = useState(false);
  const onCopyToClipboard = (ethAddress) => {
    navigator.clipboard.writeText(ethAddress);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const getChildren = (data) => {
    const length = data?.length;
    return (
      <div>
        {data
          ? data.map((elm, idx) => {
              const {
                playerEthAddress,
                userName = "Unnamed",
                profilePic,
                rank,
              } = elm;
              return (
                <div key={idx} className="player-stat-card">
                  <div className="player-rank">{rank}</div>
                  <div className="player-image-container">
                    {profilePic?.thumbnailPath ? (
                      <div className="profile-pic-container">
                        <img src={profilePic?.thumbnailPath} alt="" />
                      </div>
                    ) : (
                      <div className="player-img">
                        <img src={"/images/BB_Head.png"} alt="" />
                      </div>
                    )}
                  </div>
                  <div className="player-details">
                    <div className="player-name">{userName}</div>
                    <UncontrolledTooltip
                      target={`player-address-top-three-board-${idx}-${type}`}
                    >
                      {copied ? `copied!` : `copy to clipboard`}
                    </UncontrolledTooltip>
                    <div
                      className="player-addr"
                      id={`player-address-top-three-board-${idx}-${type}`}
                      onClick={() => onCopyToClipboard(playerEthAddress)}
                    >
                      {truncateString(playerEthAddress)}
                    </div>
                  </div>
                </div>
              );
            })
          : null}
        {length === 0 || length === undefined ? (
          <>
            <EmptyTopThreeCard /> <EmptyTopThreeCard /> <EmptyTopThreeCard />
          </>
        ) : length === 1 ? (
          <>
            <EmptyTopThreeCard /> <EmptyTopThreeCard />
          </>
        ) : length === 2 ? (
          <EmptyTopThreeCard />
        ) : null}
      </div>
    );
  };
  const gameplayChildren = getChildren(gameplayData);
  const winsChildren = getChildren(winsData);
  return (
    <div className="card-wrapper">
      <div className="top-three-container">
        <div className="top-three-title-wrapper">
          <div className="top-three-type">BlockBot {type}s</div>
          <div className="d-flex justify-content-center align-items-center">
            <div className="top-three-val">Top 3</div>
            <UncontrolledTooltip target="top-three-info-tooltip">
              The 'Gameplay' leaders are calculated and refreshed every 12 hours
              and the 'Wins' leaders are calculated and refreshed every 24
              hours.
            </UncontrolledTooltip>
            <FaInfoCircle
              className="top-three-info"
              id="top-three-info-tooltip"
            />
          </div>
        </div>
        <div className="top-three-stats-wrapper">
          <div className="top-three-stats">
            <div className="top-three-stats-title">Gameplays</div>
            <div className="top-three-players-wrapper">{gameplayChildren}</div>
          </div>
          <div className="top-three-stats">
            <div className="top-three-stats-title">Wins</div>
            <div className="top-three-players-wrapper">{winsChildren}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EmptyTopThreeCard = () => {
  return (
    <div className="empty-player-stat-card">
      <div className="empty-avatar" />
      <div className="empty-text-container">
        <div className="empty-text" />
        <div className="empty-text-long" />
      </div>
    </div>
  );
};
