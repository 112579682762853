export * from "./dataHelpers";
export * from "./recaptcha";

export const truncateString = (value, start = 5, end = 5) => {
  if (value && typeof value === "string") {
    return (
      value.substr(0, start) +
      "..." +
      value.substr(value.length - end, value.length)
    );
  }
  return "";
};
