import {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { LeaderboardRankTable, Paginator } from "../../components";
import { getLeaderboardTableData } from "../../api";
import { getPages } from "../../utils";

export const LeaderboardTab = forwardRef((props, ref) => {
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState({
    isLoading: false,
    showLoader: false,
  });

  const [paginationState, setPaginationState] = useState({
    hasNext: false,
    pageSize: 10,
    pages: [],
    totalItems: 0,
  });

  const [sortState, setSortState] = useState({ sortBy: null, sortOrder: null });
  const [currentTab, setCurrentTab] = useState("OWNED");
  const [currentPage, setCurrentPage] = useState(1);

  const getTableData = useCallback(
    async ({ isLoading, showLoader }) => {
      setLoading({ isLoading, showLoader });
      const { data, total, isNext, pageSize } = await getLeaderboardTableData({
        currentPage,
        sortState,
        searchString: props.searchInput,
        bbType: currentTab,
        matchTypeFilter: props.filterValue[0],
        timeFilter: props.filterValue[1],
      });
      setPaginationState((prevState) => ({
        ...prevState,
        pageSize,
        totalItems: total,
        hasNext: isNext,
        pages: getPages({ total, pageSize }),
      }));

      setTableData(data);
      setLoading({ isLoading: false, showLoader: false });
    },
    [currentPage, currentTab, props.searchInput, sortState, props.filterValue]
  );

  useEffect(() => {
    getTableData({ isLoading: true, showLoader: true });
  }, [getTableData]);

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const onSortClick = (sortByOption) => {
    setSortState((prevState) => {
      const { sortBy, sortOrder } = prevState;
      if (sortByOption !== sortBy)
        return { sortBy: sortByOption, sortOrder: "ASC" };
      if (sortOrder === "DESC")
        return { sortBy: sortByOption, sortOrder: "ASC" };
      else return { sortBy: sortByOption, sortOrder: "DESC" };
    });
  };

  const { pages } = paginationState;

  useImperativeHandle(ref, () => {
    return {
      getTableData,
      loading,
    };
  });

  if (loading.showLoader)
    return (
      <div className="loader d-flex justify-content-center align-items-center">
        <div
          className="spinner-border"
          role="status"
          style={{ color: "mediumpurple" }}
        ></div>
      </div>
    );

  return (
    <>
      <div className="leaderboard-row">
        {/* <div className="leaderboard-controls">
          <p>Filter:</p>
          <Button
            className={clx(
              "default-btn",
              currentTab === "OWNED" && "btn-active"
            )}
            onClick={() => setCurrentTab("OWNED")}
          >
            Owned
          </Button>
          <Button
            className={clx(
              "default-btn",
              currentTab === "BOOKED" && "btn-active"
            )}
            onClick={() => setCurrentTab("BOOKED")}
          >
            Booked
          </Button>
        </div> */}
      </div>
      <LeaderboardRankTable
        sortState={sortState}
        onSort={onSortClick}
        tableData={tableData}
      />
      <div className="pagination-wrapper">
        <Paginator
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={onPageChange}
        />
      </div>
    </>
  );
});
