import axios from "axios";
import { useState } from "react";
import styles from "./Rewards.module.scss";
import classNames from "classnames";
import Header from "../../components/Header";
import { Input } from "reactstrap";
import { BASE_API_URL, BACKEND_SECRET_WORD } from "../../constants";
import { InputGroup, InputGroupText } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import moment from "moment";

const Rewards = () => {
  const [data, setData] = useState(null);
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const onSearchClick = async () => {
    try {
      setLoading(true);
      setData(null);
      await axios
        .get(
          `${BASE_API_URL}/admin/rewardHistory/${address}?secret=${BACKEND_SECRET_WORD}`
        )
        .then((response) => {
          setData(response.data.data);
        });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <title>BlockBots - Rewards</title>
      <Header />
      <div className="main-container">
        <div className="main-bg-wrapper">
          <div className="main-wrapper">
            <div className="flexbox-row">
              <div className={classNames("card-wrapper", "w-100")}>
                <div className="leaderboard-container">
                  <div className="leaderboard-row">
                    <div
                      className={classNames(
                        styles.rewardsContentContainer,
                        "container"
                      )}
                    >
                      <div className={classNames("d-flex mt-4")}>
                        <p className={classNames(styles.ethAddressLabel)}>
                          Your ETH/MATIC Address
                        </p>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="0xabcd...."
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            className={classNames(styles.ethAddressSearch)}
                          />
                          <InputGroupText
                            onClick={onSearchClick}
                            className={classNames(styles.searchIconContainer)}
                          >
                            <FaSearch
                              className={classNames(styles.searchIcon)}
                            />
                          </InputGroupText>
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                  <h1 className={classNames(styles.rewardsTitle)}>Rewards</h1>
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border"
                        role="status"
                        style={{ color: "mediumpurple" }}
                      ></div>
                    </div>
                  ) : null}
                  {data ? (
                    <div className={classNames("container")}>
                      <table
                        className={classNames("w-100", styles.rewardsTable)}
                      >
                        <thead>
                          <tr>
                            <th>Sl No</th>
                            <th>Date</th>
                            <th>Competiton</th>
                            <th>Reward</th>
                            <th>Role</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, index) => {
                            const time = item?.createdAt;
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {time
                                    ? moment(time).format("DD MMMM YYYY")
                                    : null}
                                </td>
                                <td>
                                  {item?.leaderboardType === "GAMEPLAY"
                                    ? `Gameplays`
                                    : item?.leaderboardType === "WINS"
                                    ? `Wins`
                                    : null}
                                </td>
                                <td>{item?.playerInfo?.ethValue} ETH</td>
                                <td>{item?.bbStatus}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rewards;
