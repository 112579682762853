import { Pagination } from "reactstrap";
import ReactPaginate from "react-paginate";
export const Paginator = ({ pages, currentPage, setCurrentPage }) => {
  return (
    <Pagination className="pagination-bar" size="lg">
      <ReactPaginate
        nextLabel=">"
        onPageChange={setCurrentPage}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pages.length}
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="previous-page-item"
        previousLinkClassName="previous-page-link"
        nextClassName="next-page-item"
        nextLinkClassName="next-page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        forcePage={currentPage - 1}
      />
    </Pagination>
  );
};
