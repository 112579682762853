// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrizeCard_LeftContainer__Co58s {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n.PrizeCard_LeftContainer__Co58s .PrizeCard_WhiteBoldText__3IJzX {\n  font-family: \"GT America Bold\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 28px;\n  line-height: 30px;\n  color: #ffffff;\n  margin-bottom: 10px;\n  text-align: left;\n  max-width: 180px;\n}", "",{"version":3,"sources":["webpack://./src/components/PrizeCard/PrizeCard.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AACF;AACE;EACE,8BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;AACJ","sourcesContent":[".LeftContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n\n  .WhiteBoldText {\n    font-family: \"GT America Bold\";\n    font-style: normal;\n    font-weight: 700;\n    font-size: 28px;\n    line-height: 30px;\n    color: #ffffff;\n    margin-bottom: 10px;\n    text-align: left;\n    max-width: 180px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LeftContainer": "PrizeCard_LeftContainer__Co58s",
	"WhiteBoldText": "PrizeCard_WhiteBoldText__3IJzX"
};
export default ___CSS_LOADER_EXPORT___;
