import { useState } from "react";
import clx from "classnames";
import { Progress, Button, UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import { FRONTEND_GAME_URL, MATCHES_TWEET_MESSAGE } from "../../constants";
const encodedMessage = encodeURIComponent(MATCHES_TWEET_MESSAGE);
const encodedTweetURL = `https://twitter.com/intent/tweet?text=${encodedMessage}`;

export const MatchCard = ({ data }) => {
  const {
    gameDate,
    rounds,
    participant1: pOne,
    participant2: pTwo,
    _id,
    matchType,
  } = data;
  const [linkCopy, setLinkCopy] = useState(false);
  const totalRounds = rounds.length;
  const finalRound = rounds[totalRounds - 2];
  const { result: pOneResult, participantDetails: pOneDetails } = pOne;
  const { result: pTwoResult, participantDetails: pTwoDetails } = pTwo;
  const {
    basePowers: pOneBasePowers,
    userName: pOneName = "Unnamed",
    profilePic: pOneProfilePic,
  } = pOneDetails;
  const {
    basePowers: pTwoBasePowers,
    userName: pTwoName = "Unnamed",
    profilePic: pTwoProfilePic,
  } = pTwoDetails;
  const { health1: pOneHealth, health2: pTwoHealth } = finalRound;
  const pOneTotalHealth = pOneBasePowers.filter(
    (elm) => elm.powerType === "HEALTH_POINTS"
  )[0];
  const pTwoTotalHealth = pTwoBasePowers.filter(
    (elm) => elm.powerType === "HEALTH_POINTS"
  )[0];

  const onCopyLinkToClipboard = (link) => {
    navigator.clipboard.writeText(link);
    setLinkCopy(true);
    setTimeout(() => {
      setLinkCopy(false);
    }, 500);
  };

  const pOneSTR = pOneBasePowers.filter(
    (elm) => elm.powerType === "STRENGTH"
  )[0].value;
  const pOneAGI = pOneBasePowers.filter((elm) => elm.powerType === "AGILITY")[0]
    .value;
  const pOneDEF = pOneBasePowers.filter((elm) => elm.powerType === "DEFENSE")[0]
    .value;
  const pTwoSTR = pTwoBasePowers.filter(
    (elm) => elm.powerType === "STRENGTH"
  )[0].value;
  const pTwoAGI = pTwoBasePowers.filter((elm) => elm.powerType === "AGILITY")[0]
    .value;
  const pTwoDEF = pTwoBasePowers.filter((elm) => elm.powerType === "DEFENSE")[0]
    .value;

  const dateStr = moment(gameDate * 1000).format("DD/MM/YYYY, h:mm A");
  const isLive = Date.now() - moment(gameDate * 1000) < 20000;

  return (
    <div className="leaderbord-matches-component">
      <div className="match-card">
        <div className="match-card-header">
          <div className={clx("match-card-header-status", "match-winner")}>
            {pOneResult === "WON" ? "WINNER" : "DEFEATED"}
          </div>
          <div className={clx("match-type")}>
            {matchType.toLowerCase() === "blockbout"
              ? "CHAMPIONSHIP"
              : matchType}
          </div>
          <div className={clx("match-card-header-status", "match-lost")}>
            {pTwoResult === "WON" ? "WINNER" : "DEFEATED"}
          </div>
        </div>
        <div className="match-details-wrapper">
          <div className="match-details-body">
            <div className="match-details">
              <div className="player-details">
                <div className="player-image-container">
                  {pOneProfilePic?.thumbnailPath ? (
                    <div className="profile-pic-container">
                      <img src={pOneProfilePic?.thumbnailPath} alt="" />
                    </div>
                  ) : (
                    <div className="player-img">
                      <img src={"/images/BB_Head.png"} alt="" />
                    </div>
                  )}
                </div>
                <div className="player-name">
                  {pOneName.length > 10
                    ? pOneName.substring(0, 8) + "..."
                    : pOneName}
                </div>
              </div>
              <div className="bot-details">
                <Progress
                  className="health-progress-bar"
                  value={pOneHealth}
                  max={pOneTotalHealth.value}
                  barClassName="custom-bar"
                >
                  <div className="healthbar-content">
                    <img src="/images/health-icon.png" alt="" />
                    <span>Health Points</span>
                  </div>
                </Progress>
                <div className="bot-stats">
                  <div className={clx("stat-card", "agi-bg")}>
                    <img src="/images/agi-icon.png" alt="" />
                    <span>{pOneAGI}</span>
                  </div>
                  <div className={clx("stat-card", "def-bg")}>
                    <img src="/images/def-icon.png" alt="" />
                    <span>{pOneDEF}</span>
                  </div>
                  <div className={clx("stat-card", "str-bg")}>
                    <img src="/images/str-icon.png" alt="" />
                    <span>{pOneSTR}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="vs-text">vs</div>
            <div className={clx("match-details", "reverse-row-flex")}>
              <div className="player-details">
                <div className="player-image-container">
                  {pTwoProfilePic?.thumbnailPath ? (
                    <div className="profile-pic-container">
                      <img src={pTwoProfilePic?.thumbnailPath} alt="" />
                    </div>
                  ) : (
                    <div className="player-img-flipped">
                      <img src={"/images/BB_Head.png"} alt="" />
                    </div>
                  )}
                </div>
                <div className="player-name">
                  {pTwoName.length > 10
                    ? pTwoName.substring(0, 8) + "..."
                    : pTwoName}
                </div>
              </div>
              <div className="bot-details">
                <Progress
                  className="health-progress-bar"
                  value={pTwoHealth}
                  max={pTwoTotalHealth.value}
                  barClassName="custom-bar"
                >
                  <div className="healthbar-content">
                    <img src="/images/health-icon.png" alt="" />
                    <span>Health Points</span>
                  </div>
                </Progress>
                <div className="bot-stats">
                  <div className={clx("stat-card", "agi-bg")}>
                    <img src="/images/agi-icon.png" alt="" />
                    <span>{pTwoAGI}</span>
                  </div>
                  <div className={clx("stat-card", "def-bg")}>
                    <img src="/images/def-icon.png" alt="" />
                    <span>{pTwoDEF}</span>
                  </div>
                  <div className={clx("stat-card", "str-bg")}>
                    <img src="/images/str-icon.png" alt="" />
                    <span>{pTwoSTR}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="match-socials-container">
            <div className="match-date">
              {isLive ? (
                <div className="live-tag-container">
                  <div className="ring-container">
                    <div className="ringring"></div>
                    <div className="circle"></div>
                  </div>
                  <p>LIVE</p>
                </div>
              ) : null}{" "}
              {dateStr}
            </div>

            <div className="match-socials">
              <Button
                href={`${FRONTEND_GAME_URL}/fight?id=${_id}`}
                target="_blank"
                className="watch-match-btn"
              >
                Watch Match
              </Button>
              <span>Share :</span>
              <UncontrolledTooltip
                target={`match-twitter-${_id}`}
                placement="left"
              >
                Share the enjoyment on Twitter!
              </UncontrolledTooltip>
              <a
                href={encodedTweetURL}
                target="_blank"
                rel="noreferrer"
                id={`match-twitter-${_id}`}
              >
                <img src="/images/twitter.png" alt="" />
              </a>
              <UncontrolledTooltip
                target={`player-address-leaderboard-${_id}`}
                placement="left"
              >
                {linkCopy ? `copied!` : `copy to clipboard`}
              </UncontrolledTooltip>
              <img
                src="/images/link.png"
                alt=""
                rel="noreferrer"
                target="_blank"
                className="link-icon-copy-hover"
                onClick={() =>
                  onCopyLinkToClipboard("https://game.blockbots.gg/")
                }
                id={`player-address-leaderboard-${_id}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
