import React, { Fragment } from "react";
import clx from "classnames";

import styles from "./FilterList.module.scss";

const FilterList = (props) => {
  console.log(props.filterList);

  return (
    <div className={clx(styles.FilterListContainer)}>
      <section className={clx(styles.ListContainer)}>
        <Fragment>
          <section className={clx(styles.HeaderContainer)}>
            <p>{props.filterList.name}</p>
            <p
              className={clx(styles.resetText)}
              onClick={() => props.resetFilter(props.filterList.name)}
            >
              RESET
            </p>
          </section>
          {props.filterList.filters.map((data, index) => (
            <div className={clx(styles.ListItem)} key={index}>
              <input
                type="checkbox"
                checked={props.filters === data}
                onClick={() => props.filterHandler(data, props.filterList.name)}
              />
              <p>{data}</p>
            </div>
          ))}
        </Fragment>
      </section>
    </div>
  );
};

export default FilterList;
