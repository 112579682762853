export const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;
export const BACKEND_SECRET_WORD = process.env.REACT_APP_BACKEND_SECRET_WORD;
export const FRONTEND_GAME_URL = process.env.REACT_APP_FRONTEND_GAME_URL;
export const FRONTEND_DASHBOARD_URL =
  process.env.REACT_APP_FRONTEND_DASHBOARD_URL;
export const FRONTEND_MARKETPLACE_URL =
  process.env.REACT_APP_FRONTEND_MARKETPLACE_URL;

export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const RECAPTCHA_THRESHOLD = process.env.REACT_APP_RECAPTCHA_THRESHOLD;

export const LEADERBOARD_TWEET_MESSAGE = `I'm climbing up the ranks of the @BlockBotsNFT leaderboard! 

  - Free-to-play tournament
  - Prizes: $33,000+ & 50 #NFTs 
  - Daily winners & prizes 
  
  #BlockBotsAlpha #P2E
  
  ${FRONTEND_GAME_URL}
  `;

export const MATCHES_TWEET_MESSAGE = `Come try and outwit me in epic battles on @BlockBotsNFT V1!

Let's see if you have what it takes :crossed_swords:
#BlockBots #P2E
https://blockbots.gg
  `;

export const isPROD = process.env.REACT_APP_ENV === "PROD";

export const GA_TRACKING_ID = isPROD ? "G-XMZE8ME2WE" : null;
