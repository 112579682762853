import clx from "classnames";

import styles from "./PrizeCard.module.scss";
import BgImage from "../../assets/images/Bg.png";

export const PrizeCard = () => {
  return (
    <div
      className="card-wrapper"
      style={{
        background: `url(${BgImage})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="prize-card-container">
        <div className={clx(styles.LeftContainer)}>
          <div className={clx("card-heading", styles.WhiteBoldText)}>
            BlockBots V1 Tournaments
          </div>
          <div className="card-heading-timer card-heading-timer-custom">
            Coming soon!
          </div>
        </div>
        <div className="prize-body">
          {/* <div className="prize-value-container">
            <div className="prize-value">500</div>
            <div className="prize-currency">MATIC</div>
          </div> */}
          {/* <div
            className="relative-img-container"
            style={{
              marginLeft: "170px",
            }}
          >
            <img
              className="prize-card-img"
              src="/images/NFT_1.png"
              alt="prize-card"
            />
          </div> */}
        </div>
        {/* <div className="prize-nft">
          <div className="prize-value-container">
            <div className="prize-value">50</div>
            <div className="prize-currency">NFTs</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
