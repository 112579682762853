import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { getLiveMatchesData } from "../../api";
import { MatchCard } from "../../components";
import { getPages } from "../../utils";
import { Paginator } from "../../components";

export const MatchesTab = forwardRef((props, ref) => {
  const [matchData, setMatchData] = useState(null);
  const [loading, setLoading] = useState({
    isLoading: false,
    showLoader: false,
  });
  const [paginationState, setPaginationState] = useState({
    hasNext: false,
    pageSize: 10,
    pages: [],
    totalItems: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);

  const getMatchData = useCallback(
    async ({ isLoading, showLoader }) => {
      setLoading({ isLoading, showLoader });
      const { data, total, isNext, pageSize } = await getLiveMatchesData({
        currentPage,
        searchString: props.searchInput,
        matchTypeFilter: props.filterValue[0],
        timeFilter: props.filterValue[1],
      });
      setPaginationState((prevState) => ({
        ...prevState,
        pageSize,
        totalItems: total,
        hasNext: isNext,
        pages: getPages({ total, pageSize }),
      }));
      setMatchData(data);
      setLoading({ isLoading: false, showLoader: false });
    },
    [currentPage, props.filterValue, props.searchInput]
  );

  useEffect(() => {
    getMatchData({ isLoading: true, showLoader: true });
  }, [getMatchData]);

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const { pages } = paginationState;

  useImperativeHandle(ref, () => {
    return {
      getMatchData,
      loading,
    };
  });

  if (loading.showLoader)
    return (
      <div className="loader container d-flex justify-content-center align-items-center">
        <div
          className="spinner-border"
          role="status"
          style={{ color: "mediumpurple" }}
        ></div>
      </div>
    );

  return (
    <div className="live-matches-wrapper">
      {matchData
        ? matchData.map((elm, idx) => {
            return <MatchCard data={elm} key={idx} />;
          })
        : null}
      <div className="pagination-wrapper">
        <Paginator
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={onPageChange}
        />
      </div>
    </div>
  );
});
