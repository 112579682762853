import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import clx from "classnames";

import EnjoyingBlockImg from "../../assets/images/Enjoying-Blockbouts.png";
import styles from "./Timer.module.scss";

const second = 1000,
  minute = second * 60,
  hour = minute * 60,
  day = hour * 24;
export const Timer = () => {
  // const endDate = "Tuesday, May 03, 2022 02:30:00 PM";
  const endDate = "03 May 2022 09:00:00 GMT";
  // "19 April 2022 09:00:00 GMT"
  const [timerObj, setTimerObj] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  useEffect(() => {
    const timerId = setInterval(initTimer, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  const initTimer = async () => {
    const now = new Date().getTime();
    const countdownEndTime = new Date(endDate).getTime();
    const distance = countdownEndTime - now;
    const days = distance > 0 ? Math.floor(distance / day) : 0;
    const hours = distance > 0 ? Math.floor((distance % day) / hour) : 0;
    const minutes = distance > 0 ? Math.floor((distance % hour) / minute) : 0;
    const seconds = distance > 0 ? Math.floor((distance % minute) / second) : 0;

    setTimerObj({
      days,
      hours,
      minutes,
      seconds,
    });
  };

  // const { days, hours, minutes, seconds } = timerObj;
  // const paddedDays = days.toString().length > 1 ? days.toString() : `0${days}`;
  // const paddedHours =
  //   hours.toString().length > 1 ? hours.toString() : `0${hours}`;
  // const paddedMinutes =
  //   minutes.toString().length > 1 ? minutes.toString() : `0${minutes}`;
  // const paddedSeconds =
  //   seconds.toString().length > 1 ? seconds.toString() : `0${seconds}`;
  return (
    <div className={clx("cardWrapperTimer", styles.CardContainer)}>
      <div>
        <div className="text-center">
          {/* <div className="card-heading">The Public Alpha will end in</div> */}
          <div className={clx(styles.ImageContainer)}>
            <div className={clx("card-heading-timer", styles.LeftContainer)}>
              <p className={clx(styles.WhiteBoldText)}>Enjoying BlockBots?</p>
              <p className={clx(styles.subText)}>Let us know in our Discord!</p>
              <a
                href={`http://discord.gg/Dy2CdKnNJT`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  className={clx("default-btn", "btn-active", "btn-small")}
                >
                  <p className={clx(styles.buttonBoldText)}>Discord</p>
                </Button>
              </a>
            </div>
            <img src={EnjoyingBlockImg} alt="enjoying-blockbouts" />
          </div>
        </div>
        {/* <div className="timer-body">
          <div className="timer-metric-container">
            <div className="timer-value">{paddedDays}</div>
            <div className="timer-unit">DAYS</div>
          </div>
          <div className="timer-separator">:</div>
          <div className="timer-metric-container">
            <div className="timer-value">{paddedHours}</div>
            <div className="timer-unit">HOURS</div>
          </div>
          <span className="timer-separator">:</span>
          <div className="timer-metric-container">
            <div className="timer-value">{paddedMinutes}</div>
            <div className="timer-unit">MIN</div>
          </div>
          <span className="timer-separator">:</span>
          <div className="timer-metric-container">
            <div className="timer-value">{paddedSeconds}</div>
            <div className="timer-unit">SEC</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
