import React from "react";
import clx from "classnames";

import styles from "./SideFilter.module.scss";

import SearchIcon from "../../assets/Side_Filters/search-icon.svg";
import FilterList from "./FilterList/FilterList";
import FilterData from "../../utils/Filters.json";

const SideFilter = (props) => {
  return (
    <div className={clx(styles.SideFilterContainer)}>
      <section>
        <div className={clx(styles.SearchInputContainer)}>
          <input
            placeholder="Search"
            value={props.searchInput}
            onChange={(e) => props.onSearch(e.target.value)}
            type="text"
          />
          <img src={SearchIcon} alt="filter-menu-icon" />
        </div>
      </section>
      <section className={clx(styles.TopContainer)}>
        <div className={clx(styles.TopLeftContainer)}>
          <p>Filters</p>
        </div>
      </section>
      <section className={clx(styles.FilterList)}>
        {props.filterOption === "leaderboard" ? (
          <>
            <FilterList
              filterList={FilterData.Leaderboard[0]}
              filterHandler={props.filterUpdater}
              filters={props.filterValues[0]}
              resetFilter={props.resetFilter}
            />
            <FilterList
              filterList={FilterData.Leaderboard[1]}
              filterHandler={props.filterUpdater}
              filters={props.filterValues[1]}
              resetFilter={props.resetFilter}
            />
          </>
        ) : (
          <>
            <FilterList
              filterList={FilterData.Matches[0]}
              filterHandler={props.filterUpdater}
              filters={props.filterValues[0]}
              resetFilter={props.resetFilter}
            />
            <FilterList
              filterList={FilterData.Matches[1]}
              filterHandler={props.filterUpdater}
              filters={props.filterValues[1]}
              resetFilter={props.resetFilter}
            />
          </>
        )}
      </section>
    </div>
  );
};

export default SideFilter;
