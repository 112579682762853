import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Rewards from "./pages/Rewards";
// import ReactGA from "react-ga";
// import { GA_TRACKING_ID } from "./constants";
// import { useEffect } from "react";

// GA_TRACKING_ID && ReactGA.initialize(GA_TRACKING_ID);

function App() {
  // useEffect(() => {
  //   if (GA_TRACKING_ID) ReactGA.pageview(window.location.pathname);
  // }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="rewards" element={<Rewards />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
