import { useCallback, useEffect, useState } from "react";
import { TopThreeBoard } from "../../components";
import {
  getTopThreeLeaderboardGameplayData,
  getTopThreeLeaderboardWinsData,
} from "../../api";

export const TopThreeSection = () => {
  const [topThreeLeaderboardData, setTopThreeLeaderboardData] = useState(null);
  const getDataByType = ({ gameplayData, winsData }) => {
    return {
      owner: {
        wins: winsData?.ownerWins?.playerInfo || [],
        gameplay: gameplayData?.ownerGameplay?.playerInfo || [],
      },
      booker: {
        wins: winsData?.bookerWins?.playerInfo || [],
        gameplay: gameplayData?.bookerGameplay?.playerInfo || [],
      },
    };
  };
  const getTopThreeData = useCallback(async () => {
    const { gameplayData } = await getTopThreeLeaderboardGameplayData();
    const { winsData = {} } = await getTopThreeLeaderboardWinsData();
    const { booker, owner } = getDataByType({ gameplayData, winsData });
    setTopThreeLeaderboardData({ booker, owner });
  }, []);

  useEffect(() => {
    getTopThreeData();
  }, [getTopThreeData]);

  const ownerGameplay = topThreeLeaderboardData?.owner?.gameplay || null;
  const ownerWins = topThreeLeaderboardData?.owner?.wins || null;
  const bookerGameplay = topThreeLeaderboardData?.booker?.gameplay || null;
  const bookerWins = topThreeLeaderboardData?.booker?.wins || null;
  return (
    <div className="flexbox-row">
      <TopThreeBoard
        gameplayData={ownerGameplay}
        winsData={ownerWins}
        type="Owner"
      />
      <TopThreeBoard
        gameplayData={bookerGameplay}
        winsData={bookerWins}
        type="Booker"
      />
    </div>
  );
};
