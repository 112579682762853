import { useState, useCallback, useEffect } from "react";
import { Table } from "reactstrap";
import { truncateString } from "../../utils";
import { UncontrolledTooltip } from "reactstrap";
import { getNumberOfGameplaysAndUniquePlayers } from "../../api";
import TwitterIcon from "../../assets/Side_Filters/twitter.svg";
import LinkIcon from "../../assets/Side_Filters/link.svg";
import CheckIcon from "../../assets/images/check-icon.svg";

export const LeaderboardRankTable = ({ tableData, onSort }) => {
  const [copied, setCopied] = useState(false);
  const [linkCopy, setLinkCopy] = useState(false);
  const onCopyToClipboard = (ethAddress) => {
    navigator.clipboard.writeText(ethAddress);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const onCopyLinkToClipboard = (link) => {
    navigator.clipboard.writeText(link);
    setLinkCopy(true);
    setTimeout(() => {
      setLinkCopy(false);
    }, 500);
  };

  const [uniquePlayers, setUniquePlayers] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const getGameplayAndUniquePlayers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getNumberOfGameplaysAndUniquePlayers();
      setUniquePlayers(response.uniquePlayers);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getGameplayAndUniquePlayers();
  }, [getGameplayAndUniquePlayers]);

  const getTableRows = () => {
    return tableData
      ? tableData.map((elm, idx) => {
          console.log(elm);
          const {
            gamesPlayed,
            won,
            eloRating,
            playerEthAddress,
            userName = "Unnamed",
            bbStatus,
            rank,
            profilePic,
            userType,
          } = elm;
          console.log(elm);

          const LEADERBOARD_TWEET_MESSAGE = `I am playing the @BlockBotsNFT V1!

My stats -
⚔️Matches Played - ${gamesPlayed}
🏆Leaderboard Ranking - ${rank}${uniquePlayers ? `/` : ""}${
            uniquePlayers ? uniquePlayers : ""
          }

#BlockBots #P2E

https://blockbots.gg
`;

          const encodedMessage = encodeURIComponent(LEADERBOARD_TWEET_MESSAGE);
          const encodedTweetURL = `https://twitter.com/intent/tweet?text=${encodedMessage}`;
          return (
            <tr key={idx}>
              <th scope="row" className="table-default-column rank-text">
                {rank}
              </th>
              <td className="table-default-column">{eloRating}</td>
              <td>
                <div className="table-name-column">
                  <div className="player-image-container">
                    {profilePic?.thumbnailPath ? (
                      <div className="profile-pic-container">
                        <img src={profilePic?.thumbnailPath} alt="" />
                      </div>
                    ) : (
                      <div className="player-img-table">
                        <img src={"/images/BB_Head.png"} alt="" />
                      </div>
                    )}
                  </div>
                  <div className="player-details-table">
                    <div className="player-name-table">{userName}</div>
                    <UncontrolledTooltip
                      target={`player-address-leaderboard-${idx}`}
                      placement="left"
                    >
                      {copied ? `copied!` : `copy to clipboard`}
                    </UncontrolledTooltip>
                    <div className="icon-flex-container">
                      <div
                        className="player-addr-table"
                        onClick={() => onCopyToClipboard(playerEthAddress)}
                        id={`player-address-leaderboard-${idx}`}
                      >
                        {truncateString(playerEthAddress)}
                      </div>
                      {userType === "bot" && (
                        <img
                          src={CheckIcon}
                          alt="check-icon"
                          style={{
                            marginTop: "-38px",
                            marginLeft: "4px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </td>
              <td className="table-default-column">{gamesPlayed}</td>
              <td className="table-default-column">{won}</td>
              <td className="table-default-column">{bbStatus}</td>
              <div className="ranktable-link-icon">
                <p>Share: </p>
                <UncontrolledTooltip
                  target={`player-twitter-${idx}`}
                  placement="left"
                >
                  Boast your status on Twitter!
                </UncontrolledTooltip>
                <a
                  href={encodedTweetURL}
                  target="_blank"
                  rel="noreferrer"
                  id={`player-twitter-${idx}`}
                  className="twitter-icon"
                >
                  <img
                    src={TwitterIcon}
                    alt="twitter-icon"
                    id={`player-twitter-${idx}`}
                  />
                </a>
                <UncontrolledTooltip
                  target={`player-link-${idx}`}
                  placement="left"
                >
                  {linkCopy ? `copied!` : `copy to clipboard`}
                </UncontrolledTooltip>
                <img
                  src={LinkIcon}
                  alt="link-icon"
                  id={`player-link-${idx}`}
                  className="link-icon-copy-hover"
                  onClick={() =>
                    onCopyLinkToClipboard("https://game.blockbots.gg/")
                  }
                />
              </div>
              {/* <td className="table-share-column">
                <span>Share: </span>{" "}
                <UncontrolledTooltip
                  target={`player-twitter-${idx}`}
                  placement="left"
                >
                  Share this competition on social media to qualify for a $1000
                  giveaway!
                </UncontrolledTooltip>
                <a
                  href={encodedTweetURL}
                  target="_blank"
                  rel="noreferrer"
                  id={`player-twitter-${idx}`}
                >
                  <img src="/images/twitter.png" alt="" />
                </a>
              </td> */}
            </tr>
          );
        })
      : null;
  };
  const rows = getTableRows();
  return (
    <Table responsive borderless>
      <thead>
        <tr>
          <th
            onClick={() => {
              onSort("rank");
            }}
          >
            <div className="th-wrapper">
              Rank <img src="/images/sort-arrows.png" alt="" />
            </div>
          </th>
          <th>
            <div
              onClick={() => {
                onSort("eloRating");
              }}
              className="th-wrapper"
            >
              Rating <img src="/images/sort-arrows.png" alt="" />
            </div>
          </th>
          <th>
            <div className="th-wrapper">Player</div>
          </th>
          <th>
            <div
              onClick={() => {
                onSort("gamesPlayed");
              }}
              className="th-wrapper"
            >
              Matches <img src="/images/sort-arrows.png" alt="" />
            </div>
          </th>
          <th>
            <div
              onClick={() => {
                onSort("won");
              }}
              className="th-wrapper"
            >
              Wins <img src="/images/sort-arrows.png" alt="" />
            </div>
          </th>
          <th>
            <div className="th-static">Type</div>
          </th>
        </tr>
      </thead>
      <tbody>{rows?.length ? rows : <EmptyRow />}</tbody>
    </Table>
  );
};

const EmptyRow = () => {
  return (
    <tr>
      <th scope="row" className="empty-player-stat-row">
        <div className="empty-text-container">
          <div className="empty-text" />
          <div className="empty-text-long" />
        </div>
      </th>
      <td className="empty-player-stat-row">
        <div className="empty-text-container">
          <div className="empty-text" />
          <div className="empty-text-long" />
        </div>
      </td>
      <td className="empty-player-stat-row">
        <div className="table-name-column">
          <div className="empty-avatar" />
          <div className="empty-text-container">
            <div className="empty-text" />
            <div className="empty-text-long" />
          </div>
        </div>
      </td>
      <td className="empty-player-stat-row">
        <div className="empty-text-container">
          <div className="empty-text" />
          <div className="empty-text-long" />
        </div>
      </td>
      <td className="empty-player-stat-row">
        <div className="empty-text-container">
          <div className="empty-text" />
          <div className="empty-text-long" />
        </div>
      </td>
      <td className="empty-player-stat-row">
        <div className="empty-text-container">
          <div className="empty-text" />
          <div className="empty-text-long" />
        </div>
      </td>
    </tr>
  );
};
