import { useState, useMemo, useCallback, useEffect, useRef } from "react";
// import io from "socket.io-client";
import Header from "../components/Header";
import { Button } from "reactstrap";
import clx from "classnames";
import { Timer } from "../components/Timer";
import { PrizeCard } from "../components/PrizeCard";
import { HowToPlay, GameplayAndUniquePlayers } from "../components/InfoCards";
import { LeaderboardTab, MatchesTab } from "../containers";
import io from "socket.io-client";
import { BASE_API_URL } from "../constants";
import SideFilter from "../components/SideFilter/SideFilter.jsx";

// import GiveawayModal from "../components/GiveawayModal";
import { useDebounce } from "use-debounce";
// import { GameplaysTab } from "../containers/GameplaysTab";
// import { WinsTab } from "../containers/WinsTab";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
// import { verifyRecaptcha } from "../api";
// import { RecaptchaComponent } from "../utils";
// import { RECAPTCHA_THRESHOLD } from "../constants";

function Home() {
  const [currentTab, setCurrentTab] = useState("LEADERBOARD");
  const [searchInput, setSearchInput] = useState("");
  const [debouncedValue] = useDebounce(searchInput, 500);
  const [leaderboardFilter, setLeaderboardFilter] = useState("");
  const [leaderboardTimeFilter, setLeaderboardTimeFilter] = useState("");
  const [matchesTypeFilter, setMatchesTypeFilter] = useState("");
  const [matchesTimeFilter, setMatchesTimeFilter] = useState("");
  // const [modalVisible, setModalVisible] = useState(false);
  const leaderboardTableRef = useRef();
  const matchesTableRef = useRef();
  const gameplayInfoRef = useRef();
  const gameplaysTabRef = useRef();
  const winsTabRef = useRef();

  const onConnect = useCallback(() => {
    console.log("Socket Connection Est, :)");
  }, []);

  const onPlayerUpdate = useCallback(() => {
    if (currentTab === "LEADERBOARD") {
      const { current } = leaderboardTableRef;

      if (current) {
        const { getTableData, loading } = current;
        !loading.isLoading &&
          getTableData({ isLoading: true, showLoader: false });
      }
    }
    if (currentTab === "GAMEPLAYS") {
      const { current } = gameplaysTabRef;
      if (current) {
        const { fetchGameplays, loading } = current;
        !loading.isLoading &&
          fetchGameplays({ isLoading: true, showLoader: false });
      }
    }
    if (currentTab === "WINS") {
      const { current } = winsTabRef;
      if (current) {
        const { fetchGameplays, loading } = current;
        !loading.isLoading &&
          fetchGameplays({ isLoading: true, showLoader: false });
      }
    }
  }, [currentTab]);

  const onMatchUpdate = useCallback(() => {
    const { current } = matchesTableRef;
    const gameplayCurrent = gameplayInfoRef?.current;

    if (current) {
      const { getMatchData, loading } = current;
      !loading.isLoading &&
        getMatchData({ isLoading: true, showLoader: false });
    }
    if (gameplayCurrent) {
      const { getGameplayAndUniquePlayers, isLoading } = gameplayCurrent;
      !isLoading && getGameplayAndUniquePlayers();
    }
  }, []);

  useEffect(() => {
    const newSoc = io.connect(BASE_API_URL, {
      reconnectionDelayMax: 10000,
      reconnectionAttempts: 5,
      transports: ["websocket"],
    });
    newSoc.on("connect", onConnect);
    newSoc.on("player-update", onPlayerUpdate);
    newSoc.on("match-update", onMatchUpdate);
    return () => newSoc.close();
  }, [onConnect, onMatchUpdate, onPlayerUpdate]);

  // useEffect(() => {
  //   setTimeout(() => setModalVisible(true), 4000);
  // }, []);

  // const { executeRecaptcha } = useGoogleReCaptcha();

  // const runRecaptcha = useCallback(async () => {
  //   if (executeRecaptcha) {
  //     const token = await executeRecaptcha("leaderboard");
  //     const reCaptchaResp = await verifyRecaptcha(token);
  //     const THRESHOLD = parseFloat(RECAPTCHA_THRESHOLD);
  //     if (reCaptchaResp && reCaptchaResp.score < THRESHOLD) {
  //       console.log("You have been blocked due to too many requests", {
  //         toastId: "MAYBE_BOT",
  //       });
  //       throw new Error();
  //     }
  //   }
  // }, [executeRecaptcha]);

  // useEffect(() => {
  //   runRecaptcha();
  // }, [runRecaptcha]);

  const currentComponent = useMemo(() => {
    switch (currentTab) {
      case "LEADERBOARD":
        return (
          <LeaderboardTab
            ref={leaderboardTableRef}
            searchInput={debouncedValue}
            filterValue={[leaderboardFilter, leaderboardTimeFilter]}
          />
        );
      case "MATCHES":
        return (
          <MatchesTab
            ref={matchesTableRef}
            searchInput={debouncedValue}
            filterValue={[matchesTypeFilter, matchesTimeFilter]}
          />
        );
      // case "GAMEPLAYS":
      //   return (
      //     <GameplaysTab searchInput={debouncedValue} ref={gameplaysTabRef} />
      //   );
      // case "WINS":
      //   return <WinsTab searchInput={debouncedValue} ref={winsTabRef} />;
      default:
        return null;
    }
  }, [
    currentTab,
    debouncedValue,
    leaderboardFilter,
    leaderboardTimeFilter,
    matchesTimeFilter,
    matchesTypeFilter,
  ]);

  const leaderboardFilterUpdater = (data) => {
    setLeaderboardFilter((prev) => (prev === data ? "" : data));

    // multi-selection options_code
    // if (data === "All") {
    //   setLeaderboardFilter((prev) => (prev.includes("All") ? [] : ["All"]));
    // }
    // if (leaderboardFilter.includes(data)) {
    //   setLeaderboardFilter(leaderboardFilter.filter((item) => item !== data));
    //   return;
    // }
    // setLeaderboardFilter((prev) =>
    //   prev.includes("All") ? [data] : [...prev, data]
    // );
  };

  const matchesTypeFilterUpdater = (data) => {
    setMatchesTypeFilter((prev) => (prev === data ? "" : data));

    // multi-selection options_code
    // if (data === "All") {
    //   setMatchesTypeFilter((prev) => (prev.includes("All") ? [] : ["All"]));
    // }
    // if (matchesTypeFilter.includes(data)) {
    //   setMatchesTypeFilter(matchesTypeFilter.filter((item) => item !== data));
    //   return;
    // }
    // setMatchesTypeFilter((prev) =>
    //   prev.includes("All") ? [data] : [...prev, data]
    // );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Header />
      <div className="main-container">
        <div className="main-bg-wrapper">
          <div className="main-wrapper">
            <div className="flexbox-row">
              <Timer />
              <PrizeCard />
            </div>
            <div className="flexbox-row">
              <HowToPlay />
              <GameplayAndUniquePlayers ref={gameplayInfoRef} />
            </div>
            {/* <TopThreeSection /> */}
            <div className="flexbox-row leaderboard-container">
              {currentTab === "LEADERBOARD" ? (
                <SideFilter
                  filterOption={currentTab.toLowerCase()}
                  filterValues={[leaderboardFilter, leaderboardTimeFilter]}
                  resetFilter={(categoryType = "Match Type") => {
                    if (categoryType === "Match Type") {
                      if (!leaderboardFilter) return;
                      setLeaderboardFilter("");
                    } else {
                      if (!leaderboardTimeFilter) return;
                      setLeaderboardTimeFilter("");
                    }
                  }}
                  filterUpdater={(data, categoryType = "Match Type") => {
                    console.log(data);
                    if (categoryType === "Match Type") {
                      leaderboardFilterUpdater(data);
                    } else {
                      setLeaderboardTimeFilter(data);
                    }
                  }}
                  onSearch={(value) => setSearchInput(value)}
                  searchInput={searchInput}
                />
              ) : (
                <SideFilter
                  filterOption={currentTab.toLowerCase()}
                  filterValues={[matchesTypeFilter, matchesTimeFilter]}
                  onSearch={(value) => setSearchInput(value)}
                  searchInput={searchInput}
                  resetFilter={(categoryType) => {
                    console.log("Reset Filter: ", categoryType);
                    if (categoryType === "Match Type") {
                      if (!matchesTypeFilter) return;
                      setMatchesTypeFilter("");
                    } else {
                      if (!matchesTimeFilter) return;
                      setMatchesTimeFilter("");
                    }
                  }}
                  filterUpdater={(data, categoryType) => {
                    if (categoryType === "Match Type") {
                      matchesTypeFilterUpdater(data);
                    } else {
                      setMatchesTimeFilter(data);
                    }
                  }}
                />
              )}
              <div
                className={clx("card-wrapper", "w-100")}
                style={{
                  minWidth: "990px",
                }}
              >
                <div className="leaderboard-container">
                  <div className="leaderboard-row">
                    <div className="leaderboard-controls">
                      <Button
                        className={clx(
                          "default-btn",
                          currentTab === "LEADERBOARD" && "btn-active"
                        )}
                        onClick={() => {
                          setCurrentTab("LEADERBOARD");
                          setMatchesTypeFilter("");
                          setLeaderboardFilter("");
                        }}
                      >
                        Leaderboard
                      </Button>
                      <Button
                        className={clx(
                          "default-btn",
                          currentTab === "MATCHES" && "btn-active"
                        )}
                        onClick={() => {
                          setCurrentTab("MATCHES");
                          setLeaderboardFilter("");
                        }}
                      >
                        Matches
                      </Button>
                      {/* <Button
                        className={clx(
                          "default-btn",
                          currentTab === "GAMEPLAYS" && "btn-active"
                        )}
                        onClick={() => setCurrentTab("GAMEPLAYS")}
                      >
                        Gameplays
                      </Button>
                      <Button
                        className={clx(
                          "default-btn",
                          currentTab === "WINS" && "btn-active"
                        )}
                        onClick={() => setCurrentTab("WINS")}
                      >
                        Wins
                      </Button> */}
                    </div>
                    {/* <div className="leaderboard-search">
                      <Input
                        placeholder="Search"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        type="search"
                      />
                    </div> */}
                  </div>
                  <div className="leaderboard-component-container">
                    {currentComponent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <GiveawayModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      /> */}
    </div>
  );
}

// export default RecaptchaComponent(Home);
export default Home;
