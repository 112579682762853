import { useState } from "react";
import { Navbar, NavbarBrand, NavbarToggler, Collapse } from "reactstrap";
import {
  FRONTEND_DASHBOARD_URL,
  FRONTEND_GAME_URL,
  FRONTEND_MARKETPLACE_URL,
} from "../../constants";

const HamburgerMenu = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div className={""}>
      <Navbar
        expand="lg"
        className="d-flex justify-content-between"
        color="faded"
        light
      >
        <div className="d-flex align-items-center">
          <NavbarBrand href="/" className="header-bb-logo mr-auto" />
          <div className="header-title-container">
            <p className="header-title">BlockBots</p>
            <p className="header-subtitle">Leaderboard</p>
          </div>
        </div>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse
          className="mobile-nav-collapse"
          isOpen={!collapsed}
          navbar={true}
        >
          <div className="hamburger-nav-items mx-auto">
            <a
              href={`http://discord.gg/Dy2CdKnNJT`}
              target="_blank"
              rel="noreferrer"
            >
              Join Discord
            </a>
            <a href={FRONTEND_DASHBOARD_URL} target="_blank" rel="noreferrer">
              Dashboard
            </a>
            <a href={FRONTEND_MARKETPLACE_URL} target="_blank" rel="noreferrer">
              Marketplace
            </a>
            <a href={"/rewards"} target="_blank" rel="noreferrer">
              My Rewards
            </a>
          </div>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default HamburgerMenu;
