import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import HamburgerMenu from "./HamburgerMenu";
import {
  FRONTEND_DASHBOARD_URL,
  FRONTEND_GAME_URL,
  FRONTEND_MARKETPLACE_URL,
} from "../../constants";
const Header = (props) => {
  const { onConnectClick, account } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 991px)" });

  const navigate = useNavigate();

  const onNavClick = () => {
    navigate("/");
  };

  if (isMobile)
    return <HamburgerMenu onConnectClick={onConnectClick} account={account} />;
  return (
    <div className="header-navbar">
      <Navbar className="header-nav-bar">
        <div
          onClick={onNavClick}
          className="d-flex align-items-center header-container"
        >
          <NavbarBrand className="header-bb-logo" />
          <div className="header-title-container">
            <p className="header-title">BlockBots</p>
            <p className="header-subtitle">Leaderboard</p>
          </div>
        </div>
        <div className="header-nav-items mx-auto">
          <a
            href={`http://discord.gg/Dy2CdKnNJT`}
            target="_blank"
            rel="noreferrer"
          >
            Join Discord
          </a>
          <a href={FRONTEND_DASHBOARD_URL} target="_blank" rel="noreferrer">
            Dashboard
          </a>
          <a href={FRONTEND_MARKETPLACE_URL} target="_blank" rel="noreferrer">
            Marketplace
          </a>
          <a href={"/rewards"} target="_blank" rel="noreferrer">
            My Rewards
          </a>
        </div>
      </Navbar>
    </div>
  );
};

export default Header;
