import clx from "classnames";
import { Button } from "reactstrap";
import {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { getNumberOfGameplaysAndUniquePlayers } from "../../api";
export const HowToPlay = () => {
  return (
    <div className="card-wrapper">
      <div className="how-to-play-card">
        <div className={clx("relative-img-container", "wd20", "img-wrap")}>
          <img className="arena-img" src="/images/Arena_1.png" alt="" />
        </div>
        <div className="how-to-text-container">
          <div className="how-to-title">How to Play</div>
          <div className="how-to-desc">
            Playbook for the strategies & gameplay
          </div>
        </div>
        <div className="how-to-btn-cont">
          <a
            href={"https://docs.blockbots.gg"}
            target="_blank"
            rel="noreferrer"
          >
            <Button className={clx("default-btn", "btn-active", "btn-small")}>
              Learn more
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export const GameplayAndUniquePlayers = forwardRef((props, ref) => {
  const [totalGameplays, setTotalGameplays] = useState(null);
  const [uniquePlayers, setUniquePlayers] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const getGameplayAndUniquePlayers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getNumberOfGameplaysAndUniquePlayers();
      setTotalGameplays(response.totalGamePlay);
      setUniquePlayers(response.uniquePlayers);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getGameplayAndUniquePlayers();
  }, [getGameplayAndUniquePlayers]);

  useImperativeHandle(ref, () => {
    return {
      getGameplayAndUniquePlayers,
      isLoading,
    };
  });

  return (
    <div className="card-wrapper">
      <div className="game-stats-card">
        <div className="how-to-text-container">
          <div className="how-to-desc">
            Number of Games Played
            <div className="how-to-title mt-1">{totalGameplays}</div>
          </div>
        </div>
        <div className={clx("relative-img-container", "wd20", "info-img-wrap")}>
          <img className="info-img" src="/images/More_Info.png" alt="" />
        </div>
        <div className="how-to-desc">
          Total Unique Players
          <div className="how-to-title mt-1">{uniquePlayers}</div>
        </div>
      </div>
    </div>
  );
});
